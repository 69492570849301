<template>
  <sign-page
    title-text="执业医护人员统计"
    :request="request"
    :column-list="columnList"
    :title-menus="[]"
    table-size="large"
    :tableActions="[]">
  </sign-page>
</template>

<script>
import {
  workerRequest,
  jobTitleRequest,
  workerCertificateRequest
} from '@/api'

export default {
  computed: {
    columnList: {
      get () {
        let data = [{
          title: '所属员工姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '所属职务',
          field: 'positionName',
          sort: true,
          filters: this.$store.getters.positionList.map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '执业证书类型',
          field: 'doctorPraTypes',
          filters: this.$store.getters.selectDatas['practicing_certificate_type'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          }),
          filterMethod (parm) {
            if (parm.value === '__null') {
             return [undefined, '', null].includes(parm.row.doctorPraTypes)
            }
            if (parm.row.doctorPraTypes) {
              let l = parm.row.doctorPraTypes.split(',')
              return l.includes(parm.value)
            } else {
              return false
            }
          }
        },
        {
          title: '当前所属岗位类型',
          field: 'jobTitles',
          filters: this.$store.getters.jobTitleList.map(v => v.data.type + v.data.title + '级').map(v => {
            return {
              label: v,
              value: v
            }
          }),
          filterMethod (parm) {
            if (parm.value === '__null') {
             return [undefined, '', null].includes(parm.row.jobTitles)
            }
            if (parm.row.jobTitles) {
              let l = parm.row.jobTitles.split(',')
              return l.includes(parm.value)
            } else {
              return false
            }
          }
        }]
        return data
      }
    }
  },
  created () {
    this.$store.dispatch('loadJobTitleList')
    this.$store.dispatch('loadPositionList')
  },
  methods: {
    async loadData () {
      let workerData = await workerRequest.get()
      let jobTitleData = await jobTitleRequest.getCurrent()
      let allDoctorPraDatas = await workerCertificateRequest.get({
        type: 'doctor_pra'
      })
      let newData = []
      workerData.forEach(v => {
        let doctorPraDatas = allDoctorPraDatas.filter(v1 => v1.workerId === v.id && v1.mType && (v1.mType.includes('医') || v1.mType.includes('护')))
        if (doctorPraDatas.length > 0) {
          let findJobTitles = jobTitleData.filter(v1 => v1.workerId === v.id)
          let dataItem = {
            workerName: v.name,
            positionName: v.positionData ? v.positionData.name : '',
            doctorPraTypes: doctorPraDatas.map(v1 => v1.mType).join(','),
            jobTitles: findJobTitles.map(v1 => v1.jobTitleType + v1.jobTitleTitle + '级').join(',')
          }
          newData.push(dataItem)
        }
      })
      return newData 
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      }
    }
  }
}
</script>

<style lang="less" scoped>
.zyzs-bjjl {
  min-height: 10rem;
}
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  padding: 8px 16px;
  margin: 1rem 0;
}
.log-info {
  display: flex;
  min-height: 2rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.log-info-t {
  color: #515a6e;
}
</style>